.quiz {
    padding-top: 17%;
}

.quiz_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-bottom: 20%;
}

.quiz_container .q_a {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.quiz_container .q_a .answers { 
    width: fit-content;
    text-align: start;
    font-size: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 6%;
}

.quiz_container .q_a .answers .container {
    border: 1px solid black;
    border-radius: 15px;
    width: 100%;
    padding: 2% 3%;
    display: flex;
    justify-content: space-between;
}

.quiz_container .info_bubble {
    margin: auto;
    width: 45%;
    border: 2px solid;
    padding: 1.5%;
    font-weight: 900;
}

.quiz_container .q_a .answers .container:hover { 
    cursor: pointer;
    background-color: #69a4dc;
    transition: .3s;
}



/* FUN FACT  */


.fun_fact_container {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    border: 1px solid;
    padding: 2%;
    text-align: center;
    border-radius: 10px;
}

.fun_fact_container p {
    line-height: 2.5;
    font-size: 2.3rem;
    font-style: italic;
}

.fun_fact_container span {
    font-weight: 900;
}

.fun_fact_redirects {
    padding-top: 1.5%;
    margin: auto;
    width: 38%;
}

.fun_fact_redirects button {
    width: 100%;
    border-radius: 10px;
}

/* question three */


.question_three {
    width: 60%;
}

.question_three .q_a .questions {
    padding-bottom: 4%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.question_three button {
    margin: auto;
    display: block;
}


/* Skin conditions */


.skin_input {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.skin_input form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.skin_input input {
    width: 100%;
    margin-top: 5%;
    padding: 1%;
}

.skin_input button {
   display: block;
   margin: auto;
}


/* Question five  */

.skin_goals_button{
    display: block;
    margin: auto;
}

/* retreive client information */

.client_info {
    display: flex;
    flex-direction: column;
    gap: 85px;
    text-align: center;
}

.client_info h1 {
    text-transform: capitalize;
}

.client_info form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.client_info form .client_name{
    display: flex;
    justify-content: space-evenly;
}

form .client_name .container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.container input {
    padding: 1.5%;
    margin-top: 1.5%;
    border-radius: 4px;
    width: 70%;
}

.client_info .email {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 5%;
}

.client_info .email input {
    padding: 1.5%;
    margin-top: 1.5%;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 2%;
}

.client_info button {
    margin: auto;
}


/* RESULTS  */

.verify_results form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
    width: 100%;
    margin: 10% 0;
}

.verify_results form input {
    width: 100%;
    border: 0;
    background: none;
    font-size: 1.8rem;
}

.verify_results form a {
    margin: auto;
    text-align: center;
    width: 100%;
    display: block;
}

.verify_results form input:hover {
    cursor: default;
}

.verify_results .verify_question {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid;
    padding-bottom: 1%;
}

.verify_results .verify_question .question {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.verify_results .editBtn {
    border: 1px solid;
}

.verify_results h1 {
    text-transform: capitalize;
    text-align: center;
}

.verify_results h2 {
    font-size: 1.9rem;
    font-weight: 700;
}

.verify_results button {
    margin: auto;
}

/* Thank you card  */

.thank_you {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    gap: 20px;
    text-align: center;
    text-transform: capitalize;
}

@media screen and (min-width: 280px) and (max-width:320px){
    .quiz {
        padding-top: 80%;
    }

    .quiz_container {
        padding: 2%;
        margin-bottom: 10%;
    }

    .quiz_container h1 {
        font-size: 2.6rem;
    }

    /* Retrieve info  */

    .client_info {
        gap: 50px;
    }

    .client_info h1 {
        font-size: 2.5rem;
    }

    .client_info form .client_name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    form .client_name .container {
        flex-direction: column;
        gap: 0;
    }

    .container input {
        width: 95%;
        margin-bottom: 1%;
    }

    .client_info .email {
        flex-direction: column;
        gap: 0;
        padding: 1%;
    }

    .client_info form {
        gap: 20px;
    }

    /* question One  */

    .quiz_container .info_bubble {
        width: 100%;
    }

    /* Question Three  */

    .question_three {
        width: 100%;
    }

    .question_three h1 {
        font-size: 2.2rem;
    }

    .question_three form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .question_three .q_a .questions {
        gap: 20px;
    }

    .thank_you {
        padding-top: 10%;
        width: 90%;
    }
    .thank_you p {
        font-size: 2.2rem;
    }

    .verify_results form {
        margin: 20% 0 ;
    }
}

@media screen and (min-width: 321px) and (max-width: 360px){
    .quiz {
        padding-top: 70%;
    }

    .quiz_container {
        padding: 2%;
    }

    .quiz_container h1 {
        font-size: 3.2rem;
    }

    .quiz_container {
        padding: 1%;
        margin-bottom: 10%;
    }

    /* Retrieve info  */

    .client_info {
        gap: 50px;
    }

    .client_info h1 {
        font-size: 2.5rem;
    }

    .client_info form .client_name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    form .client_name .container {
        flex-direction: column;
        gap: 0;
    }

    .container input {
        width: 95%;
        margin-bottom: 1%;
    }

    .client_info .email {
        flex-direction: column;
        gap: 0;
        padding: 1%;
    }

    .client_info form {
        gap: 20px;
    }

    /* question One  */

    .quiz_container .info_bubble {
        width: 100%;
    }

    /* Question Three  */

    .question_three {
        width: 100%;
    }

    .question_three h1 {
        font-size: 2.2rem;
    }

    .question_three form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .question_three .q_a .questions {
        gap: 20px;
    }

    .thank_you {
        width: 90%;
    }
    .thank_you p {
        font-size: 2.2rem;
    }

    .verify_results form {
        margin: 20% 0 ;
    }
}

@media screen and (min-width: 361px) and (max-width: 390px){
    .quiz {
        padding-top: 65%;
    }

    .quiz_container {
        padding: 2%;
        margin-bottom: 10%;
    }

    /* Retrieve info  */

    .client_info {
        gap: 50px;
    }

    .client_info h1 {
        font-size: 2.5rem;
    }

    .client_info form .client_name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    form .client_name .container {
        flex-direction: column;
        gap: 0;
    }

    .container input {
        width: 95%;
        margin-bottom: 1%;
    }

    .client_info .email {
        flex-direction: column;
        gap: 0;
        padding: 1%;
    }

    .client_info form {
        gap: 20px;
    }

    /* question One  */

    .quiz_container .info_bubble {
        width: 100%;
    }

    /* Fun Fact  */


    .fun_fact_container {
        width: 100%;
    }

    /* Question Three  */

    .question_three {
        width: 100%;
    }

    .question_three h1 {
        font-size: 2.2rem;
    }

    .question_three form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .question_three .q_a .questions {
        gap: 20px;
    }

    .thank_you {
        width: 90%;
    }
    .thank_you p {
        font-size: 2.2rem;
    }

    .verify_results form {
        margin: 20% 0 ;
    }
}


@media screen and (min-width: 391px) and (max-width: 430px){
    .quiz {
        padding-top: 55%;
    }
    .quiz_container {
        padding: 2%;
        margin-bottom: 10%;
    }

    /* Retrieve info  */

    .client_info {
        gap: 50px;
    }

    .client_info h1 {
        font-size: 2.5rem;
    }

    .client_info form .client_name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    form .client_name .container {
        flex-direction: column;
        gap: 0;
    }

    .container input {
        width: 95%;
        margin-bottom: 1%;
    }

    .client_info .email {
        flex-direction: column;
        gap: 0;
        padding: 1%;
    }

    .client_info form {
        gap: 20px;
    }

    /* question One  */

    .quiz_container .info_bubble {
        width: 100%;
    }

    /* Fun Fact  */


    .fun_fact_container {
        width: 100%;
    }

    /* Question Three  */

    .question_three {
        width: 100%;
    }

    .question_three h1 {
        font-size: 2.2rem;
    }

    .question_three form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .question_three .q_a .questions {
        gap: 20px;
    }

    .thank_you {
        width: 90%;
    }
    .thank_you p {
        font-size: 2.2rem;
    }

    .verify_results form {
        margin: 20% 0 ;
    }
}


@media screen and (min-width: 431px) and (max-width: 460px){
    .quiz {
        padding-top: 50%;
    }
    .quiz_container {
        padding: 2%;
        margin-bottom: 10%;
    }

    /* Retrieve info  */

    .client_info {
        gap: 50px;
    }

    .client_info h1 {
        font-size: 2.5rem;
    }

    .client_info form .client_name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    form .client_name .container {
        flex-direction: column;
        gap: 0;
    }

    .container input {
        width: 95%;
        margin-bottom: 1%;
    }

    .client_info .email {
        flex-direction: column;
        gap: 0;
        padding: 1%;
    }

    .client_info form {
        gap: 20px;
    }

    /* question One  */

    .quiz_container .info_bubble {
        width: 100%;
    }

    /* Fun Fact  */


    .fun_fact_container {
        width: 100%;
    }

    /* Question Three  */

    .question_three {
        width: 100%;
    }

    .question_three h1 {
        font-size: 2.2rem;
    }

    .question_three form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .question_three .q_a .questions {
        gap: 20px;
    }

    .thank_you {
        width: 90%;
    }
    .thank_you p {
        font-size: 2.2rem;
    }

    .verify_results form {
        margin: 20% 0 ;
    }
}
@media screen and (min-width: 461px) and (max-width: 500px){
    .quiz {
        padding-top: 45%;
    }
    .quiz_container {
        padding: 2%;
        margin-bottom: 10%;
    }

    /* Retrieve info  */

    .client_info {
        gap: 50px;
    }

    .client_info h1 {
        font-size: 2.5rem;
    }

    .client_info form .client_name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    form .client_name .container {
        flex-direction: column;
        gap: 0;
    }

    .container input {
        width: 95%;
        margin-bottom: 1%;
    }

    .client_info .email {
        flex-direction: column;
        gap: 0;
        padding: 1%;
    }

    .client_info form {
        gap: 20px;
    }

    /* question One  */

    .quiz_container .info_bubble {
        width: 100%;
    }

    /* Fun Fact  */


    .fun_fact_container {
        width: 100%;
    }

    /* Question Three  */

    .question_three {
        width: 100%;
    }

    .question_three h1 {
        font-size: 2.2rem;
    }

    .question_three form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .question_three .q_a .questions {
        gap: 20px;
    }

    .thank_you {
        width: 90%;
    }
    .thank_you p {
        font-size: 2.2rem;
    }

    .verify_results form {
        margin: 20% 0 ;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px){
    .quiz {
        padding-top: 40%;
    }
    .quiz_container {
        padding: 2%;
        margin-bottom: 10%;
    }

    /* Retrieve info  */

    .client_info {
        gap: 50px;
    }

    .client_info h1 {
        font-size: 2.5rem;
    }

    .client_info form .client_name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    form .client_name .container {
        flex-direction: column;
        gap: 0;
    }

    .container input {
        width: 95%;
        margin-bottom: 1%;
    }

    .client_info .email {
        flex-direction: column;
        gap: 0;
        padding: 1%;
    }

    .client_info form {
        gap: 20px;
    }

    /* question One  */

    .quiz_container .info_bubble {
        width: 100%;
    }

    /* Fun Fact  */


    .fun_fact_container {
        width: 100%;
    }

    /* Question Three  */

    .question_three {
        width: 100%;
    }

    .question_three h1 {
        font-size: 2.2rem;
    }

    .question_three form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .question_three .q_a .questions {
        gap: 20px;
    }

    .thank_you {
        width: 90%;
    }
    .thank_you p {
        font-size: 2.2rem;
    }

    .verify_results form {
        margin: 20% 0 ;
    }
}


@media screen and (min-width:551px) and (max-width: 600px) {
    .quiz {
        padding-top: 38%;        
    }
    .quiz_container { 
        padding: 2%;
        padding-bottom: 20%;
    }
    .quiz_container .info_bubble {
        width: 75%;
    }


    /* Fun Fact  */


    .fun_fact_container {
        width: 90%;
    }

    /* Quiz three  */

    .question_three { 
        width: 100%;
    }
    .skin_condition_button {
        width: 75%;
    }

    .q_a form {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px){
    .quiz {
        padding-top: 30%;
    }
    
}

@media screen and (min-width: 1024px){
    .quiz {
        padding-top: 20%;
    }

    .client_info .email {
        padding-bottom: 10%;
    }

    .client_info form { 
        gap: 45px;
    }
}