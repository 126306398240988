.checkout {
    padding-top: 16%;
    display: flex;
    justify-content: space-evenly;
    height: 100vh;
    overflow: hidden;
}

.checkout_container {
    width: 50%;
    padding-left: 5%;
    overflow: auto;
    height: auto;
}
.checkout_container::-webkit-scrollbar {
    display: none;
}

.checkout_container h1 {
    border-bottom: 2px solid black;
    padding-bottom: 2%;
}

.checkout .checkout_container form .container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.checkout_container form {
    display: flex;
    flex-direction: column;
    gap: 55px;
    padding-top: 4%;
}

.checkout_container form input {
    padding: 1.5%;
    background-color: #fbe9e2;
    border: none;
    border-bottom: 1px solid black;
}

.checkout_container form label { 
    font-size: 1.9rem;
}

.checkout_container form input:focus { 
    border: 1px dotted black;
}

.checkout_container form strong { 
    font-size: 1.5rem;
    color: rgb(110, 110, 110);
}



#submit {
    border: 1px solid black;
    width: 50%;
    margin: auto;
    margin-bottom: 2%;
}

.video_container video {
    height: 500px;
}



/* SUCCESS  */

.success {
    padding-top: 15%;
    display: flex;
    width: 95%;
    margin: auto;
}
.left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.left p {
    font-size: 2rem;
    line-height: 2.5;
}

.right {
    width: 50%;
    text-align: center;
}

.right img {
    width: 100%;
}



/* CANCEL  */


.cancel {
    padding-top: 22%;
}

.left p {
    font-size: 2rem;
    line-height: 2.5;
}

@media screen and (max-width: 767px) {
    .checkout {
        padding-top: 55%;
    }
    .checkout_container {
        width: 100%;
        padding: 1%;
        padding-bottom: 30%;
    }
    .success { 
        padding-top: 55%;
        flex-direction: column;
        text-align: center;
        width: 97%;
        gap: 10px;
    }
    .right {
        width: 100%;
    }
    .left button {
        display: block;
        width: 95%;
        margin: auto;
    }
    .left {
        width: 100%;
    }
}