.faq {
    padding-top: 16%;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
}

.faq-banner {
    text-align: center;
}

.faq-title {
    text-align: center;
}

.faq-accordion {
    border: 1px solid; 
    margin: 5%;
}

.accordion button {
    font-size: 1.6rem;
    font-weight: 800;
}

.accordion-body li {
    line-height: 2;
    list-style-type: circle;
}

@media screen and (min-width: 280px) and (max-width:320px){
    .faq {
        width: 95%;
        padding-top: 85%;
    }
}
@media screen and (min-width: 321px) and (max-width: 360px){
    .faq {
        width: 95%;
        padding-top: 73%;
    }
}
@media screen and (min-width: 361px) and (max-width: 390px){
    .faq {
        width: 95%;
        padding-top: 65%;
    }
}
@media screen and (min-width: 391px) and (max-width: 430px){
    .faq {
        width: 95%;
        padding-top: 60%;
    }
}
@media screen and (min-width: 431px) and (max-width: 460px){
    .faq {
        width: 95%;
        padding-top: 60%;
    }
}
@media screen and (min-width: 461px) and (max-width: 500px) {
    .faq {
        width: 95%;
        padding-top: 46%;
    }
}
@media screen and (min-width: 501px) and (max-width: 550px) {
    .faq {
        width: 95%;
        padding-top: 46%;
    }
}
@media screen and (min-width:551px) and (max-width: 600px) {
    .faq {
        width: 95%;
        padding-top: 40%;
    }
}
@media screen and (min-width:601px) and (max-width: 767px) {
    .faq {
        width: 95%;
        padding-top: 35%;
    }
}
@media screen and (min-width:768px) and (max-width: 850px) {
    .faq {
        width: 95%;
        padding-top: 30%;
    }
}
@media screen and (min-width:851px) and (max-width: 950px) {
    .faq {
        width: 95%;
        padding-top: 25%;
    }
}
@media screen and (min-width:951px) and (max-width: 1024px) {
    .faq {
        width: 95%;
        padding-top: 25%;
    }
}
