.about { 
    padding-top: 15%;
}

.about_landing {
    padding: 1%;
}

.about_landing .about_container {
    display: flex;
    align-items: center;
    height: 300px;
}

.about_landing .about_container .img {
    height: 300px;
    width: 30%;
}

.about_landing .about_container .img img {
    height: 100%;
    width: 100%;
}

.about_landing .about_container .flex1 {
    display: flex;
    align-items: center;
    text-align: center;
}

.about_landing .about_container h1 {
    font-size: 3rem;
    text-align: center;
}

.content_two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: #ddd3ea;
}

.about_landing .about_container .container_one {
    height: 100%;
    width: 70%;
    background-color: pink;
}

.p {
    width: 90%;
    margin: auto;
}

.about_container .center {
    width: 60%;
}

.empty_div { 
    width: 30%;
    height: 100%;
    background-color: blanchedalmond;
}

.about_landing_mobile {
    padding-top: 19%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    padding-bottom: 5%;
}

.about_landing_mobile .mobile_container p{
    padding: 1%;
}

.about_landing_mobile .first {
    background-color: #f4bfca;
}

.about_landing_mobile .third {
    background-color: #e2f4fb;
}

@media screen and (min-width: 280px) and (max-width: 330px) {
    .about_landing_mobile {
        padding-top: 65%;    
    }
    .about_landing_mobile h1 
    {
        font-size: 3rem;
        padding-bottom: 1.5%;
        text-align: center;
    }

    .about_landing_mobile .mobile_container {
        padding: 2%;
    }
}

@media screen and (min-width: 331px) and (max-width: 380px) {
    .about_landing_mobile {
        padding-top: 50%;
    }
}

@media screen and (min-width: 381px) and (max-width: 440px){
    .about_landing_mobile {
        padding-top: 40%;
    }
}

@media screen and (min-width: 441px) and (max-width: 530px){
    .about_landing_mobile {
        padding-top: 30%;
    }
}

@media screen and (min-width: 531px) and (max-width: 600px){
    .about_landing_mobile {
        padding-top: 24%;
    }
}

@media screen and (min-width: 601px) and (max-width: 680px){
    .about_landing_mobile {
        padding-top: 20%;
    }
}

@media screen and (min-width: 681px) and (max-width: 767px){
    .about_landing_mobile {
        padding-top: 16%;
    }
}

@media screen and (min-width: 768px) and (max-width: 830px){
    .about_landing {
        padding-top: 15%;
    }

    .about_landing .about_container {
        display: flex;
        align-items: center;
    }
}

@media screen and (min-width: 831px) and (max-width: 900px){
    .about_landing {
        padding-top: 10%;
    }
}

@media screen and (min-width: 901px) and (max-width: 950px){
    .about_landing {
        padding-top: 10%;
    }
    .about_landing .about_container .flex{ 
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: #6f00ff;
    }
}

@media screen and (min-width: 952px) and (max-width: 1023px){
    .about_landing {
        padding-top: 10%;
    }
}

@media screen and (min-width: 1024px){
    .about_landing {
        padding-top: 5%;
    }
}