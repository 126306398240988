.home_container{
    /* padding-top: 16%; */
    display: flex;
    flex-direction: column;
    gap: 100px;
}


/* LANDING SECTION  */

.landingPage_container{
    display: flex;
    background-color: rgb(255, 240, 242);
}

.landingPage_container .image{
    height: 490px;
    width: 100%;
}

.landingPage_container .image .img{
    height: 100%;
    width: 95%;
}

.landingPage_container .content{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    padding-right: 1%;
}

.landingPage_container .content h1{
    font-size: 3.2rem;
}

.landingPage_container .content h2{
    font-size: 2.2rem;
}

.landingPage_container .content p{
    line-height: 2;
}

.landingPage_container .content .bottom{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.landingPage_container .content .bottom button{
    margin-top: 1.5%;
}



/* HOT PRODUCTS  */

.hot_products_container{
    display: flex;
    justify-content: space-between;
    padding: 2.5%;
    text-align: center;
    background-color: rgb(255, 240, 242);
}

.hot_products_container .content{
    margin: auto;
}

.hot_products_container .content .image h1{
    font-size: 3rem;
    color: black;
}

.hot_products_container .content .image .img{
    width: 750px;
}


/* SKIN PLAYLIST SECTION  */

.skin_playlist_container{
    background-color: rgb(255, 240, 242);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1%;
}

.skin_playlist_container h1 {
    font-size: 3.2rem;
}

.skin_playlist_container p {
    line-height: 2;
}

.skin_playlist_container .image{
    width: 260px;
    text-align: center;
}

.skin_playlist_container .image .img{
    width: 100%;
}

.skin_playlist_container .content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
}

@media screen and (max-width: 767px) {
    .entrance-container .image-container {
        width: 100vw;
        height: 729px;
    }

    .image-container img{ 
        width: 100%;
        height: 1300px;
    }

    /* Landing  */

    .home_container {
        width: 100vw;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    
    .landingPage_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;
        padding-top: 25% ;
        margin-top: 25%;
    }

    .landingPage_container { 
        display: flex;
        flex-direction: column-reverse;
    }

    .landingPage_container .content {
        width: 90%;
        text-align: center;
    }

    .landingPage_container .image .img {
        width: 100%;
        height: 75%;
    }

    .landingPage_container .image a {
        display: block;
        text-align: center;
    }

    /* Hottest Products  */

    
    .hot_products_container {
        padding-top: 5%;
    }

    /* Skin Playlist  */

    .skin_playlist_container {
        display: flex;
        flex-direction: column;
        padding-top: 5%;
    }

    .skin_playlist_container h1 {
        text-align: center;
        display: block;
    }

    .skin_playlist_container .content {
        width: 90%;
        text-align: center;
    }

    .skin_playlist_container .image {
        width: 100%;
    }
}

/* Tablet: */

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .landingPage_container .content {
        text-align: start;
    }
    .landingPage_container .content button {
        width: 100%;
    }
    .landingPage_container .image .img {
        width: 95%;
        height: 95%;
    }
}
