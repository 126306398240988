.cart_page{
    padding-top: 20%;
    background-color: rgb(255, 240, 242);
}

.cart_container h1{
    padding-bottom: 3%;
}

.cart_container{
    margin: auto;
    text-align: center;
    width: 85%;
    padding: 1.5%;
    margin-bottom: 5%;
}

.cart_container .header{
    display: grid;
    grid-template-columns: repeat(4, 2fr);    
    font-size: 2.2rem;
}

.cart_container .container{
    border-bottom: 1px solid black;
    border-top: 1px solid black;
}

.cart_container .cart_info{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
}

.cart_container .cart_info .description h2{
    font-size: 2rem;
}

.cart_item_list .cart_info .image img{
    width: 50%;
}

.cart_container .cart_info .quantity{
    font-size: 2.6rem;
    display: flex;
    align-items: center;
    margin: auto;
    gap: 10px;
}

.quantity_display {
    display: flex;
    align-items: center;
    gap: 10px
}

.cart_container .cart_info .total{
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin: auto;
}

.cart_final_total {
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    padding: 4% 0; 
}

.navigate_options {
    margin: 4% 0;
    font-size: 2rem;
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 50%;
    margin: auto;
}

.navigate_options .navigate_container {
    width: 100%;
}

.navigate_options .return_to_shop {
    background-color: #6aa4dc;
}

@media screen and (min-width: 280px) and (max-width: 360px) { 
    .cart_page {
        padding-top: 80%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    } 
       .cart_empty {
        display: flex;
        flex-direction: column;
        gap: 19px;
        margin-bottom: 20%;
    }
       .cart_page .cart_empty button {
        width: 100%;
    }
       .cart_container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
       .cart_item_list { 
        display: flex;
        padding: 5% 0;
    }
    .navigate_options {
        flex-direction: column;
    }
    .quantity_display {
        flex-direction: column;
    }
}

@media screen and (min-width: 361px) and (max-width: 430px) {
    .cart_page {
        padding-top: 66%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    } 
       .cart_empty {
        display: flex;
        flex-direction: column;
        gap: 19px;
        margin-bottom: 20%;
    }
       .cart_page .cart_empty button {
        width: 100%;
    }
       .cart_container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
       .cart_item_list { 
        display: flex;
        padding: 5% 0;
    }
    .navigate_options {
        flex-direction: column;
    }
}

@media screen and (min-width: 431px) and (max-width: 530px) {
    .cart_page {
        padding-top: 50%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    } 
       .cart_empty {
        display: flex;
        flex-direction: column;
        gap: 19px;
        margin-bottom: 20%;
    }
       .cart_page .cart_empty button {
        width: 100%;
    }
       .cart_container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
       .cart_item_list { 
        display: flex;
        padding: 5% 0;
    }
    .navigate_options {
        flex-direction: column;
    }
}

@media screen and (min-width: 531px) and (max-width: 630px) {
    .cart_page {
        padding-top: 40%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    } 
       .cart_empty {
        display: flex;
        flex-direction: column;
        gap: 19px;
        margin-bottom: 20%;
    }
       .cart_page .cart_empty button {
        width: 100%;
    }
       .cart_container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
       .cart_item_list { 
        display: flex;
        padding: 5% 0;
    }
}

@media screen and (min-width: 631px) and (max-width: 767px)  {
   .cart_page {
    padding-top: 35%;
    display: flex;
    flex-direction: column;
    gap: 50px;
   } 
   .cart_empty {
    display: flex;
    flex-direction: column;
    gap: 19px;
    margin-bottom: 20%;
   }
   .cart_page .cart_empty button {
    width: 100%;
   }
   .cart_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
   }
   .cart_item_list { 
    display: flex;
    padding: 5% 0;
   }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .cart_page {
        padding-top: 30%;
    }
}
