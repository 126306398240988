.navigation{
    position: fixed;
    width: 100%;
    color: rgb(248 248 248);
    z-index: 99;
    transition: .25s;
}

.addObserverColor {
    background-color: #fbe9e2;
    transition: .4s;
}

.navigation .nav_bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5%;
}

.navigation .info{
    background-color: #69a4dc;
    color: whitesmoke;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    padding: 1.5%;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.links{
    background-color:#69a4dc;
    position: absolute;
    height: 100vh;
    top: 0%;
    left: 0;
    padding-right: 10%;
    padding-left: 3%;
    transition: .255s;
}

.nav_close{
    position: absolute;
    left: -100%;
    top: 0%;
    height: 100vh;
    transition: .5s;
    padding-right: 10%;
    padding-left: 3%;
}

.navigation .nav_links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    height: 58%;
}
.nav_links a{
    font-family: 'Times New Roman', Times, serif;
    color: whitesmoke;
    font-size: 1.8rem;
}

.navigation .nav_links li:hover{
    cursor: pointer;
    opacity: .4;
    transition: .2s;
}

.nav_links .shop_active{
    position: absolute;
    left: 160px;
    top: 50px;
    height: 100%;
    width: 100%;
    background-color: #69a4dc;
    transition: .3s;
}

.nav_links .shop_active .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    padding-left: 6.5%;
    height: 58%;
}

.nav_links .shop_not_active{
    position: absolute;
    left: -260px;
    top: 65px;
    height: 100%;
    width: 100%;
    background-color: #69a4dc;
}

.open_navigation{
    cursor: pointer;
    color: black;
}

.close_navigation{
    margin-bottom: 40%;
    cursor: pointer;
}

.logo{
    height: 150px;
}

.search{
    color: black;
}

.cart{ 
    color: black;
}
 
.logo img{
    height: 100%;
}

.right_side{
    display: flex;
    align-items: baseline;
    gap: 15px;
}

.right_side .search{
    cursor: pointer;
}

.right_side .cart{
    cursor: pointer;
}

.right_side span{
    color: red;
    font-weight: 900;
    display: flex;
    align-items: baseline;
    gap: 5px;
}

@media screen and (min-width: 280px) and (max-width: 359px){
    .links {
        padding-right: 14%;
    }
}

@media screen and (min-width: 360px) and (max-width: 430px){
    .links {
        padding-right: 12%;
    }
}

@media screen and (max-width: 767px) {

    /* Navigation  */

    .right_side { 
        display: none;
    }
    .nav_bar {
        display: flex;
        justify-content: center;
    }
    .logo { 
        text-align: center;
        width: 100%;
        padding-right: 17px;
    
    }
    .open_navigation {
        width: 8%;
    }
    .nav_links .shop_active {
        position: absolute;
        left: 120px;
        top: 0px;
    }
    .cart-mobile {
        display: block;
        margin-top: 200%;
        display: flex;
        gap: 5px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .nav_links .shop_active {
        top: 0px;
    }
}