.modal{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 240, 242);
    gap: 50px;
    z-index: -1;
}

.modal_container{
    padding-top: 18%;
    background-color: rgb(255, 240, 242);
}

.modal_container .stage{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    margin: auto;
}

.modal_container .stage .stage_options{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100px;
    gap: 10px;
}

.modal_container .stage .stage_options .img{
    width: 50%;
}

.modal_container .stage .main_stage{
    width: 70%;
}

.modal_container .stage .main_stage .img{
    width: 545px;
    height: 525px;
}

.modal_container .stage .info{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    height: 100%;
}

.modal_container .stage .info .top .price{
    font-size: 2.5rem;
    color: green;
}

.modal_container .stage .info .top{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

}
.modal_container .stage  .name{
    text-align: center;
}

.modal_container .stage .info .description {
    line-height: 2;
    font-weight: 900;
}

.modal_container .stage .info .top button{
    width: 100%;
    font-weight: 900;
}

.modal_container .stage .info .top .return_to_shop{
    background-color: #6aa4dc;
}

.price-span{
    color: green;
}


/* ACCORDIAN  */
.accordian{
    background-color: rgb(255, 240, 242);
    padding-bottom: 5%;
}

.accordian .accordians{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.accordian .container{
    border-top: 1px solid black;
    width: 50%;
    padding: 1.9%;
    margin-left: 5%;
}

.accordian h1{
    font-size: 2rem;
    cursor: pointer;
}

.accordian_drop video {
    width: 550px;
    margin: 2%;
}



/* REVIEWS  */
.reviews_container{
    background-color: rgb(255, 240, 242);
    padding: 1%;
}

.reviews_container .accordian_drop{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 4.5%;
}

.reviews_container .reviews{
    border-bottom: 1px solid black;
    letter-spacing: 1.5px;
    padding-bottom: 2%;
}


.reviews_container .reviews h2{
    font-weight: 900;
    letter-spacing: 0;
}

.write-review:hover {
    cursor: pointer;
}



/* REVIEW REVIEW_FORM */

.review_form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid rgb(252, 223, 227);
    padding: 2%;
}

.review_form .name{
    display: flex;
    gap: 15px;
}

.review_form .review{
    display: flex;
    flex-direction: column;
}

.review_form .review input{
    padding: 1%;
}

.review_form .name .name_container{
    display:flex;
    flex-direction: column;
}



/* RECOMMENDED PRODUCTS  */

.recommended_products {
    width: 90%;
    margin: auto;
}

.recommended_products h1{
    font-size: 3rem;
}

.recommended_products .images{
    overflow: auto;
    white-space: nowrap;
}

.recommended_products .images .img{
    width: 35%;
}

@media screen and (min-width: 280px) and (max-width: 359px){
    .modal {
        padding-top: 45%; 
        border: 1px solid;
    }
    .modal_container {
        width: 100%;
        padding-top: 35%;
    }
    .modal_container .stage {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
    }
    .modal_container .stage .stage_options {
        order: 2;
        flex-direction: row;
        overflow: auto;
        width: 90%;
    }
    .modal_container .stage .info {
        order: 3;
        width: 90%;
    }
    .modal_container .stage .info .top {
        padding-top: 10%;
    }
    .modal_container .stage .main_stage { 
        width: 100%;
        background-color: rgb(255, 240, 242);

    }
    .modal_container .stage .main_stage .img {
        height: 405px;
        padding: 2%;
    }

    .modal_container .stage .stage_options .img { 
        width: 70px;
    }
    .modal_container .stage .info .description {
        width: 100%;
    }
    .accordian .container {
        width: 75%;
    }
}

@media screen and (min-width: 360px) and (max-width: 430px) {
    .modal {
        padding-top: 30%;
    }
    .modal_container {
        width: 100%;
        padding-top: 35%;
    }
    .modal_container .stage {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
    }
    .modal_container .stage .stage_options {
        order: 2;
        flex-direction: row;
        overflow: auto;
        width: 90%;
    }
    .modal_container .stage .info {
        order: 3;
        width: 90%;
    }
    .modal_container .stage .info .top {
        padding-top: 10%;
    }
    .modal_container .stage .main_stage { 
        width: 100%;
        background-color: rgb(255, 240, 242);

    }
    .modal_container .stage .main_stage .img {
        height: 405px;
        padding: 2%;
    }

    .modal_container .stage .stage_options .img { 
        width: 70px;
    }
    .modal_container .stage .info .description {
        width: 100%;
    }
    .accordian .container {
        width: 75%;
    }

}

@media screen and (min-width: 431px) and (max-width: 600px) {
    .modal {
        padding-top: 13%;
    }
    .modal_container {
        width: 100%;
        padding-top: 35%;
    }
    .modal_container .stage {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
    }
    .modal_container .stage .stage_options {
        order: 2;
        flex-direction: row;
        overflow: auto;
        width: 90%;
    }
    .modal_container .stage .info {
        order: 3;
        width: 90%;
    }
    .modal_container .stage .info .top {
        padding-top: 10%;
    }
    .modal_container .stage .main_stage { 
        width: 100%;
        background-color: rgb(255, 240, 242);

    }
    .modal_container .stage .main_stage .img {
        height: 405px;
        padding: 2%;
    }

    .modal_container .stage .stage_options .img { 
        width: 70px;
    }
    .modal_container .stage .info .description {
        width: 100%;
    }
    .accordian .container {
        width: 75%;
    }
}

@media screen and (min-width: 601px) and (max-width: 767px) { 
    .modal_container {
        width: 100%;
        padding-top: 35%;
        
    }
    .modal_container .stage {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
    }
    .modal_container .stage .stage_options {
        order: 2;
        flex-direction: row;
        overflow: auto;
        width: 90%;
    }
    .modal_container .stage .info {
        order: 3;
        width: 90%;
    }
    .modal_container .stage .info .top {
        padding-top: 10%;
    }
    .modal_container .stage .main_stage { 
        width: 100%;
        background-color: rgb(255, 240, 242);
        text-align: center;

    }
    .modal_container .stage .main_stage .img {
        height: 405px;
        text-align: center;
        padding: 2%;
    }

    .modal_container .stage .stage_options .img { 
        width: 70px;
    }
    .modal_container .stage .info .description {
        width: 100%;
    }
    .accordian .container {
        width: 75%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .modal_container {
        padding-top: 28%;
    }
    .modal_container .stage {
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
    .modal_container .stage .main_stage {
        width: 50%;
        margin: auto;
    }
    .modal_container .stage .main_stage .img {
        margin: 3% 0;
    }
    .modal_container .stage .stage_options {
        flex-direction: row;
    }
    .modal_container .stage .stage_options {
        order: 2;
        width: 30%;
    }
    .modal_container .stage .info {
        order: 3;
        margin-top: 5%;
        width: 70%;
    }
    .modal_container .stage .info .description {
        order: 1;
    }
}

@media screen and (min-width: 1024px) {
    .modal_container {
        padding-top: 20%;
    }
}