.serum {
    display: flex;
    flex-direction: column;
    gap: 135px;
}

.image-container img {
    width: 100%;
}

.serum_landing_container{
    padding-top: 16%;
}


.serum_landing_container .banner{
    background-color: rgb(255, 240, 242);
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.serum_landing_container .image{
    width: 50%;
}

.serum_landing_container .image .img{
    width: 75%;
}

.serum_landing_container .banner .banner_text h1{
   text-align: center;
}

.serum_landing_container .banner .banner_text{
    font-size: 2rem;
    width: 50%;
    line-height: 2;
    padding-bottom: 5%;
    padding-left: 8%;
    color: black;
}


/* PRODUCTS SECTION */

.serum_products_container{
    padding: 2%;
    display: flex;
    justify-content: center;
    background-color: rgb(255, 240, 242);
}

.serum_products_container .images{
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
    gap: 20px;
}

.serum_products_container .images button {
    width: 100%;
}
.serum_products_container .images link .img{
    width: 100%;
}

.serum_products_container h2{
    text-align: center;
    font-size: 2.2rem;
}

.serum_products_container .images h1{
    text-align: center;
}

.serum_products_container .info {
    width: 50%;
    line-height: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2.3rem;
}


/* CAROUSEL  */

.carousel {
    height: 450px;
    margin: auto;
    background-color: rgb(255, 240, 242);
    padding: 2%;
}

.carousel-inner {
    height: 100%;
    width: 70%;
    margin: auto;
}

.carousel_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    line-height: 2.6;
    margin: auto;
}

.carousel_banner p {
    padding-bottom: 10%;
    width: 50%;
}

.carousel-control-next-icon{
    background-color: black;
}

.carousel-control-prev-icon{
    background-color: black;
}

.carousel-item .carousel_img {
   
    height: 375px;
} 
.carousel-item .carousel_img img {
    height: 100%;
}

@media screen and (min-width: 20px) and (max-width: 430px){
    .serum {
        padding-top: 45%;
    }
    .carousel-item h1 {
        padding-bottom: 5%;
    }
    .carousel_banner {
        display: flex;
        flex-direction: column;
    }
    
    .carousel_banner p {
        border: 1px solid;
        display: none;
    }

    .carousel_img {
        height: 250px;
    }

    .carousel_img img {
        height: 100%;
    }

    .carousel-inner {
        display: flex;
        align-items: center;
    }

    .serum_products_container .images { 
        width: 90%;
    }

    .serum_products_container {
        flex-direction: column;
        gap: 30px;
        width: 100%;
    }
    .serum_products_container .images {
        width: 100%;
    }
    .serum_products_container .info p {
        width: 100%;
        text-align: center;
    }
    .serum_products_container .info { 
        width: 100%;
    }

}

@media screen and (min-width: 431px) and (max-width: 767px) {
    .serum {
        padding-top: 30%;
        gap: 50px;
    }
    .serum_landing_container {
        padding-top: 0;
    }
    .carousel-item h1 {
        padding-bottom: 5%;
    }
    .carousel_banner {
        display: flex;
        flex-direction: column;
    }
    
    .carousel_banner p {
        border: 1px solid;
        display: none;
    }

    .carousel_img {
        height: 250px;
    }

    .carousel_img img {
        height: 100%;
    }

    .carousel-inner {
        display: flex;
        align-items: center;
    }

    .serum_products_container .images { 
        width: 90%;
    }

    .serum_products_container {
        flex-direction: column;
        gap: 30px;
    }
    .serum_products_container .images {
        width: 100%;
    }

    .serum_products_container .info {
        width: 100%;
        text-align: center;
        font-size: 2rem;
    }
}

/* Tablet: */

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .serum_landing_container {
        padding-top: 25%;
    }
    .carousel p {
        display: none;
    }
    .serum_products_container {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .serum_products_container .images {
        width: 100%;
    }
    .serum_products_container .images img {
        width: 100%;
    }
    .serum_products_container .info {
        width: 95%;
        text-align: center;
    }
}
