.blog {
    height: 100vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    margin: auto;
    /* padding-top: 10%; */
}

.blog h2{
    font-size: 2rem;
}

.blog_form {
    width: 40%;
}

.blog_form .name {
    width: 100%;
    display: flex;
    flex-direction: column;
  
}

.blog_form input {
    padding: 1.5%;
    margin-top: 1.5%;
    border-radius: 4px;
}

.blog_form button {
    width: 50%;
    margin-top: 1.5%;
    border-radius: 4px;
}

@media screen and (min-width: 280px) and (max-width:320px){
    .blog {
        padding-top: 50%;
        width: 90%;
    }
}
@media screen and (min-width: 321px) and (max-width: 360px){
    .blog {
        padding-top: 35%;
        width: 90%;
    }
}
@media screen and (min-width: 361px) and (max-width: 390px){
    .blog {
        padding-top: 30%;
        width: 90%;
    }
}
@media screen and (max-width: 767px) {
    .blog_form {
        width: 90%;
    }
}
@media screen and (max-width: 1023px) {
    .blog_form {
        width: 90%;
    }
}
@media screen and (min-width: 1024px) {
    .blog {
        padding-top: 15%;
    }
    .blog_form {
        width: 90%;
    }
}

