.bodyButter{
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding-top: 15%;
}

.body_landing_container{
    background-color: rgb(255, 240, 242);
    padding-top: 15px;
}

.body_landing_container .directory{
    display: flex;
    gap: 5px;
    padding-left: 1%;
}

.body_landing_container .banner{
    background-color: rgb(255, 240, 242);
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.body_landing_container .banner .banner_text h1{
    text-align: center;
}

.body_landing_container .banner .banner_content{
    font-size: 2rem;
    line-height: 2;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.body_landing_container .banner .container{
    width: 43%;
}



/* PRODUCTS SECTION */

.products_container{
    background-color: rgb(255, 240, 242);
}

.products_container h1{
    padding-right: 5%;
    padding-top: 3%;
    text-align: center;

}

.products_container .products{
    padding: 2%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

.products_container h2{
    text-align: center;
    padding-bottom: 15%;
    color: black;
}


@media screen and (max-width: 767px) {
    .products_container {
        padding: 2%; 
        padding-top: 45%;
    }
    .products_container .products {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .bodyButter {
        padding-top: 30%;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1100px) {
    .bodyButter {
        padding-top: 25%;
    }
}