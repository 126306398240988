html, body, div, span, applet, object, iframe, table, caption, tbody, tfoot, thead, tr, th, td, 
del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, 
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, 
dl, dt, dd, ol, ul, li, fieldset, form, label, legend {
    vertical-align: baseline;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    outline: 0;
    padding: 0;
    margin: 0;
    }
/* remember to define focus styles! */
:focus {
    outline: 0;
    }

*{
    padding:0;
    margin:0;
    box-sizing:border-box;
    max-width:100%;

}

/*General Styles*/
html{
    font-size:62.5%;
}
body{
    font-size:1.6rem;
    line-height:1.5;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #fbe9e2;
    background-attachment: fixed;
}
a{
    font-family: 'Roboto Mono', monospace;
    text-decoration:none;
}
h1{
    font-size:4rem;
    font-family: ‘monospace’
}
li{
  list-style-type: none;
}
button{
    padding: 1.5%;
    /* width: 30%; */
    background-color: whitesmoke;
    cursor: pointer;
}
section{
    padding:4% 0;
}

