.footer{
    background-color:#69a4dc;
    border-top: 1px solid black;
}

.footer_container{
    display: flex;
    justify-content: space-evenly;
    padding: 1.5%;
    color: whitesmoke;
}

.column{ 
    width: 25%;
}

.column h2{
    font-size: 2.2rem;
    font-weight: 900;
}

.list_items h3{
    font-size: 1.8rem;
    width: 80%;
}

#email{
    padding: 1.5%;
    margin-top: 1.5%;
    border-radius: 4px;
    width: 100%;
}

.name_inputs {
    display: flex;
    gap: 5px;
}
.name_inputs input {
    padding: 1.5%;
    margin-top: 1.5%;
    border-radius: 4px;
    width: 50%;
}

.sign_up {
    width: 100%;
    margin-top: 1.5%;
}

.list_items a {
    color: whitesmoke;
}

@media screen and (max-width: 767px) { 
    .footer_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 3%;
    }
    .column { 
        width: 100%;
        line-height: 2;
    }
    .column .list_items h3 {
        line-height: 2;
    }
}
